import "@brainhubeu/react-carousel/lib/style.css";
import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel";
import ButtonProject from "./ButtonProject";
import Call from "./Call";
import useMediaQuery from "../hook/useMediaQuery";

function Case({ index, category, title, description, links, screenshots }) {
  const isWeb = category.toLowerCase() === "web";
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <div className="flex items-center py-4 bg-gray-50">
      <div
        className={`main flex flex-col items-center gap-8 ${
          index !== 0 && index % 2 ? "lg:flex-row-reverse" : "lg:flex-row"
        }`}
      >
        <div className="w-full lg:w-1/2">
          <h2 className="H2 mb-4 flex gap-2 items-center">
            <span className="w-fit bg-green rounded-full text-xs text-white px-2 py-1 uppercase">
              {category}
            </span>
            {title}
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: description }}
            className="paragraph mb-4"
          ></div>
          <ul className="inline-flex flex-col md:flex-row gap-2 w-full">
            {links.map((item, index) => (
              <li key={index} className="w-full md:w-1/2">
                <ButtonProject url={item.url} name={item.social} />
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full lg:w-1/2">
          <div className={`mx-auto w-full`}>
            <Carousel
              plugins={
                screenshots.length > 1
                  ? [
                      "arrows",
                      "infinite",
                      {
                        resolve: slidesToShowPlugin,
                        options: {
                          numberOfSlides: isWeb ? 1 : isDesktop ? 2 : 1,
                        },
                      },
                    ]
                  : []
              }
            >
              {screenshots.map((item, index) => (
                <div
                  key={index}
                  className={`relative bg-gray-100 ${
                    isWeb ? "w-full h-[680px]" : "h-full"
                  } `}
                >
                  <img
                    className={`${isWeb ? "absolute inset-0" : "relative"}`}
                    src={item.url}
                    alt={item.url}
                    width={isWeb ? "100%" : 375}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

function Cases({ cases }) {
  const [first] = cases;
  const { title, subtitle, projects } = first;

  return (
    <section>
      <Call title={title} about={subtitle} />
      <div className="flex flex-col gap-4">
        {projects.map((item, index) => (
          <Case
            key={index}
            index={index}
            category={item.category.name}
            title={item.name}
            description={item.description.html}
            links={item.links}
            screenshots={item.screenshots}
          />
        ))}
      </div>
    </section>
  );
}

export default Cases;
