import facebook from "../assets/facebook.png";
import linkedin from "../assets/linkedin.png";
import instagram from "../assets/instagram.png";

function Social({ links }) {
  const social = {
    facebook: facebook,
    linkedin: linkedin,
    instagram: instagram,
  };

  return (
    <ul className="inline-flex gap-4">
      {links.map((item, index) => (
        <li key={index}>
          <a
            href={item.url}
            alt={item.social}
            target="_blank"
            rel="noopener noreferrer"
            className={`block w-[42px] h-[42px]`}
          >
            <img src={social[item.social.toLowerCase()]} alt={item.social} />
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Social;
