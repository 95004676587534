import logo from "../assets/symbol@2x.png";
import Social from "./Social";

function Section({ title, children }) {
  return (
    <div className="flex flex-col">
      <h6 className="H6 text-white uppercase mb-4 upperline after:bg-white">
        {title}
      </h6>
      {children}
    </div>
  );
}

function Footer({ contacts }) {
  const { subtitle, copyright, address, links } = contacts[0];

  return (
    <footer className="bg-purple">
      <div className="main">
        <div className="flex flex-col md:flex-row items-start justify-between py-12 md:py-8 gap-12 md:gap-4">
          <div className="flex flex-col gap-1">
            <img src={logo} alt="P, Physis" width={54} className="mb-2" />
            <div
              className="H2 text-white"
              dangerouslySetInnerHTML={{ __html: subtitle.html }}
            />
            <cite className="text-xs text-purple-light not-italic">
              {copyright}
            </cite>
          </div>
          <div className="flex flex-col md:flex-row w-full md:w-auto gap-8">
            <Section title="Contato">
              <div
                className="text-white"
                dangerouslySetInnerHTML={{ __html: address.html }}
              />
            </Section>
            <Section title="Social">
              <Social links={links} />
            </Section>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
