import Call from "./Call";

function BoxWeDo({ name, description, image }) {
  return (
    <div className="flex flex-col justify-center gap-4">
      <div className="flex items-center justify-center w-[40px] md:w-[50px] h-[40px] md:h-[50px] rounded-full bg-purple">
        <img src={image} alt={name} width={25} />
      </div>
      <div>
        <h3 className="H3 mb-4">{name}</h3>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
}

function WhatWeDo({ whatWeDos }) {
  const [first] = whatWeDos;
  const { title, subtitle, weDos } = first;

  return (
    <section className="section">
      <Call title={title} about={subtitle} />
      <div className="main">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full mx-auto">
          {weDos.map((item, index) => {
            return (
              <BoxWeDo
                key={index}
                name={item.name}
                description={item.description.html}
                image={item.icon.url}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default WhatWeDo;
