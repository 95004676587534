function Call({ title, about }) {
  console.log(about);
  return (
    <div className="upperline after:bg-purple mb-6">
      <div className="main">
        <h2 className="H2 text-purple pt-4">{title}</h2>
        {about && <cite className="about">{about}</cite>}
      </div>
    </div>
  );
}

export default Call;
