import logo from "../assets/logo@2x.png";
import p from "../assets/p.png";

function Header({ abouts }) {
  const [about] = abouts;

  return (
    <header className="relative bg-[url('./assets/bg.png')] bg-repeat">
      <div className="main flex flex-col">
        <img
          src={logo}
          alt="Logo original Physis"
          width={125}
          className="mt-14"
        />
        <div className="w-full md:w-1/2 mt-10 md:mt-40">
          <div
            className="H1 mb-3"
            dangerouslySetInnerHTML={{ __html: about.title.html }}
          />
          <div
            className="paragraph mb-11"
            dangerouslySetInnerHTML={{ __html: about.description.html }}
          />
        </div>
      </div>
      <img
        src={p}
        alt="P, Physis"
        className="absolute right-0 top-[230px] md:top-10 hidden md:block md:w-[500px] lg:w-[665px] z-50"
      />
    </header>
  );
}

export default Header;
