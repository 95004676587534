import WhatsAppImage from "../assets/whatsapp.png";

function WhatsApp() {
  const onOpen = () => {
    window.open("https://wa.link/fdvmss", "_blank");
  };

  return (
    <div className="fixed bottom-3 right-3 z-50">
      <button
        className="bg-green-600 px-2 sm:px-4 py-2 rounded-full flex gap-1 items-center justify-center border border-white"
        onClick={onOpen}
      >
        <img src={WhatsAppImage} width={40} className="bg-transparent" />
        <span className="hidden sm:block text-white font-bold">
          Entre em contato
        </span>
      </button>
    </div>
  );
}

export default WhatsApp;
