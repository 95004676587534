import Call from "./Call";

function Client({ clients }) {
  const [first] = clients;
  const { title, subtitle, customers } = first;

  return (
    <section className="section">
      <Call title={title} about={subtitle} />
      <div className="main">
        <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-3 gap-1 w-full lg:w-1/2">
          {customers.map((item, index) => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
              className="flex items-center justify-center p-4 bg-gray-50"
            >
              <img src={item.logo.url} alt={item.name} width={150} />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Client;
