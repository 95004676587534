import { useEffect, useState } from "react";
import Cases from "./components/Cases";
import Client from "./components/Client";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WhatWeDo from "./components/WhatWeDo";
import WhatsApp from "./components/WhatsApp";
import { getContent } from "./lib/graphcms";

function App() {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const data = await getContent();
    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <Header abouts={data.abouts} />
      <Client clients={data.clients} />
      <WhatWeDo whatWeDos={data.whatWeDos} />
      <Cases cases={data.cases} />
      <Footer contacts={data.contacts} />
      <WhatsApp />
    </>
  );
}

export default App;
