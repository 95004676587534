async function fetchAPI(query, { variables } = {}) {
  const res = await fetch(
    "https://api-sa-east-1.graphcms.com/v2/cl0sfek2d0e7m01w285f77yag/master",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NDc1NDQ1MTcsImF1ZCI6WyJodHRwczovL2FwaS1zYS1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2NsMHNmZWsyZDBlN20wMXcyODVmNzd5YWcvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiM2RhOTM1ODEtYjc4Yi00MmZmLTgyYzEtODMwYWVhNjc3YzEyIiwianRpIjoiY2wwdmRndHBhMXJqajAxeG4weWM1ZGppZSJ9.0Nih1T1jABe0aBAFejkpSmIqZbVC0nKyVkEMsxuI45pRNQuhXrZ6P6qkNckPBH_YvvFXnPF13TlixxRNHdUttLeqGjw3x5uXcBd0v9dUWPW1T3BgnFUqEYB4aJ6_KmF892qrBUtQWdgIw9wg0krXOlpVB7LXun0iEhYbSmjU_nZZyMkb_XvriuapoV6C1bZROK0iVawugCJKTDrCnhpf6eBnuOX1QpbDwo88j5FgPQS8OXSe6BmdLDI3OPHIcSJ0vuy2vIzambyE8AfCFU6XVTqtHBikNAeZn1xR4YGHpYM2r5sloSMyxnme5v2QDKmzyFC6q8gIJfdGCUFy5bNQTc4brGDP0FbBjaH-yRRBThGeGSulhnaNn-5kmUhzW-zlRg7eGEn6E5hMjKes2qCrRdBfN0rp7AjNh8NEMxVB4b4uPyDwiDVdnGPfdpsfmKRcWlTPgWPNc21cidTWIw-NLrG7OmO-5qf9km-fHRwjepXzPZpE-438AWNtUJxTVXZnYHZziN1pZdUagqGZVILRbHzFqxWFoI2Nqas6xLLg7jnOm0L9TQPhochH-T9PRk-recagI7JbHk__v0OWjFR52woNDQu7VsAeZZtkvRtgZcDDFPs_CKP4IC5BpD8tLHih1Ux7uqEHRuv5Sd2VwPHa57s69eGb4BXFO-0MM6RQGjw",
      },
      body: JSON.stringify({ query, variables }),
    }
  );

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }

  return json.data;
}

export async function getContent() {
  const data = await fetchAPI(
    `
    query Content {
      abouts {
        title {
          html
        }
        description {
          html
        }
      }
      clients {
        title
        subtitle
        customers {
          name
          logo {
            url (transformation: {
              image: { resize: { width: 200, fit: clip } }
            })
          }
          url
        }
      }
      whatWeDos {
        title
        subtitle
        weDos {
          icon {
            url
          }
          name
          description {
            html
          }
        }
      }
      cases {
        title
        subtitle
        projects {
          name
          description {
            html
          }
          links {
            social
            url
          }
          screenshots {
            url
          }
          category {
            name
          }
        }        
      }
      contacts {
        subtitle {
          html
        }
        copyright
        address {
          html
        }
        links {
          social
          url
        }
      }
    }
    
    `
  );

  return data;
}
